import React, { Fragment } from "react";
import LayoutOne from "../Layouts/LayoutOne";
import "./Pages.css";

const AboutUs = () => {
  return (
    <Fragment>
      <LayoutOne>
        <div>
          <div className="op-header">
            <div className="thm-header text-center">
              <ul className="pb-10">
                <li>
                  <a href="/about" className="c1">
                    ANASAYFA
                  </a>
                </li>
                <li className="c1">HAKKIMIZDA</li>
              </ul>
              <h1 className="c3">HAKKIMIZDA</h1>
            </div>
            {/* /.thm-header */}
          </div>
          {/* /.op-header */}
          <div className="about-page f1">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <header>
                    <figure>
                      <img src="img/dd/slider2.jpg" alt="About us" />
                      <figcaption>
                        <p className="f1 fw-3">
                        Her biri branşında uzman, tecrübeli teknisyenlerden oluşan servis kadrosu, bol alternatifli yedek parça seçenekleriyle en kaliteli hizmeti en uygun şartlarda sağlıyor. Müşteri odaklı bir anlayışla servis ve yedek parça departmanlarını bir çatı altında toplayan yönetim organizasyonuyla toplam çözümler sunuyor.
                        </p>
                      </figcaption>
                    </figure>
                  </header>
                </div>
                {/* /.col-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
            <div className="faqs">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="thm-header text-center">
                      <p className="c1 pb-10">DED Makine</p>
                      <h1 className="c3">Misyonumuz</h1>
                    </div>
                    {/* /.thm-header */}
                  </div>
                  {/* /.col-12 */}
                  <div className="col-xl-5 text-xl-left text-center mb-xl-0 mb-3 ">
                    <img
                      src="img/mission.jpg"
                      alt="Frequently Asked Questions"
                      className="mt-5"
                    />
                  </div>
                  {/* /.col-lg-6 */}
                  <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p className="text-about justify-content-center mobileFont">
                    Dinamik ve tecrübeli ekibimizle işbirliği ve sinerji oluşturmak, şirket değerlerinin sürekli iyileştirilmesi ve geliştirilmesi ve müşterinin beklentilerini en doğru şekilde karşılayacak, teknolojinin izin verdiği en pratik ve kaliteli mühendislik çözümlerini üreterek uygulamaya geçirmektir. Aynı zamanda sağlanan ürünlerle ilgili olarak; kalite, sürdürülebilirlik ve üretkenliği en üst seviyede tutmaktır.
                    </p>
                  </div>
                  {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container */}
            </div>
            {/* /.faqs */}
            <div style={{ backgroundColor: "white" }} className="faqs">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="thm-header text-center">
                      <p className="c1 pb-10">DED Makine</p>
                      <h1 className="c3">Vizyonumuz</h1>
                    </div>
                    {/* /.thm-header */}
                  </div>
                  {/* /.col-12 */}

                  <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p className="text-about mobileFont justify-content-center">
                    Verdiğimiz tüm hizmetlerde Türkiye'de en iyisi olmaya devam ederek dünyada ise  örnek şirketlerden biri olmak.


                    </p>
                  </div>
                  {/* /.col-lg-6 */}
                  <div className="col-xl-4 text-xl-left text-center mb-xl-0 mb-3">
                    <img
                      src="img/vision.jpg"
                      alt="Frequently Asked Questions"
                    />
                  </div>
                  {/* /.col-lg-6 */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container */}
            </div>
            {/* /.faqs */}
          </div>
          {/* /.about-page */}
          {/* /.page-wrapper */}
        </div>
      </LayoutOne>
    </Fragment>
  );
};
export default AboutUs;
