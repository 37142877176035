import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import Contact from "../../Pages/Contact";

const NavMenu = ({ strings, icons, texts }) => {
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <div className="site-header header-one">
        <div className="top-header">
          <div className="container clearfix">
            <div className="logo-box float-left">
              <Link to="/">
                <img style={{ maxWidth: "10em" }} src={icons.logo} alt />
              </Link>
            </div>
            {/* /.logo-box */}
            <div className="float-right right-contact-block phoneVisible">
              <div className="single-right-contact">
                <div className="icon-block">
                  <i className={icons["mapIcon"]} />
                  {/* /.Carivon-icon-placeholder */}
                </div>
                {/* /.icon-block */}
                <div className="text-block">
                  <p>
                    <span>İvedik Osb. Melih Gökçek Bulvarı 1122. Cadde Maxivedik İş Merkezi<br/> No:20 Kat:13 Daire:74 Yenimahalle/Ankara</span>
                  </p>
                </div>
                {/* /.text-block */}
              </div>
              {/* /.single-right-contact */}
              <div className="single-right-contact">
                <div className="icon-block">
                  <i className={icons["phoneIcon"]} />
                  {/* /.Carivon-icon-placeholder */}
                </div>
                {/* /.icon-block */}
                <div className="text-block">
                  <p>
                    <span>{texts["phoneNumber"]}</span>
                  </p>
                </div>
                {/* /.text-block */}
              </div>
              {/* /.single-right-contact */}

              {/* /.single-right-contact */}
              <div
                style={{ backgroundColor: "red" }}
                className="single-right-contact"
              >
                <a href="/teklifAl" className="header-btn">
                  TEKLİF AL
                </a>
                {/* /.header-btn */}
              </div>
              {/* /.single-right-contact */}
            </div>
            {/* /.float-right */}
          </div>
          {/* /.container */}
        </div>
        {/* /.top-header */}
        <div className="navbar navbar-expand-lg navbar-light header-navigation stricky header-style-one">
          <div className="container clearfix">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="logo-box clearfix">
              <button
                className="menu-toggler"
                data-target={"#main-nav-bar"}
                onClick={() => setShow(!show)}
              >
                <span className="fa fa-bars" />
              </button>
            </div>
            {/* /.logo-box */}
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="main-navigation"
              style={show ? { display: "block" } : { display: "none" }}
              id="main-nav-bar"
            >
              <ul className="navigation-box">
                <li className="current">
                  <Link style={{ textDecoration: "none" }} to="/">
                    {strings["home"]}
                  </Link>
                </li>
                <li>
                  <Link style={{ textDecoration: "none" }} to="/hakkimizda">
                    {strings["about"]}
                  </Link>
                </li>
                {/* //////////////////////////////////    değişecek    /////////////////////////////////// */}
              
                <li>
                  <Link style={{ textDecoration: "none" }} to="/teklifAl">
                    {" "}
                    {strings["getQoute"]}{" "}
                  </Link>
                </li>

                <li>
                  <a
                    style={{ textDecoration: "none" }}
                    href="/iletisim"
                  >
                    {strings["contact"]}
                  </a>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
         
            {/* /.right-side-box */}
          </div>
          {/* /.container */}
        </div>
      </div>
    </Fragment>
  );
};

export default NavMenu;
