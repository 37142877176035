import React from "react";

const SectionIntro = () => {
  return (
    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-xl-5 text-xl-left text-center">
            <div className="thm-header">
              <p className="c1 pb-10">DED Makine</p>

              <h1 className="c3">Yılların Tecrübesi İle Aynı Hizmet Anlayışı</h1>
            </div>
            {/* /.thm-header */}
          </div>
          {/* /.col-12 */}
          <div className="col-xl-6">
            <figure className="text-xl-left text-center my-xl-0 my-3">
              <img src="img/dd/sliderdd4.jpg" alt="Intro" />
            </figure>
          </div>
          {/* /.col-xl-6 */}
          <div className="col-xl-6">
            <section className="pt-xl-0 pt-3">
              <p>
              Pazar taleplerindeki değişiklikleri en iyi şekilde karşılamak amacıyla 
               kazıcı-yükleyiciler, ağır iş makinaları, kompakt ve endüstriyel ürün serisi ile inşaat ve endüstriyel sektörlerdeki 
              müşterilerine DED Makine etkin çözümler üretiyor.
               
              </p>
             
            </section>
            <div className="facts text-white text-xl-left text-center">
              <div className="d-flex justify-content-between flex-sm-row flex-column">
                <div className="single-fact">
                  <h1 className="counter">"İş Makinaları Satış & Kiralama"</h1>
                </div>
                {/* /.single-fact */}

                {/* /.single-fact */}
              </div>
              {/* /.d-flex */}
            </div>
            {/* /.facts */}
          </div>
          {/* /.col-xl-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
};

export default SectionIntro;
